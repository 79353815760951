
import moment from 'moment'
import Vue, { PropType } from 'vue'
import api from '../../api/api'
import { getSimpleReportsData } from '../../api/chisai/GCB2'

import Breadcrumbs from '../../components/Breadcrumbs.vue'
import LineChart from '../../components/charts/LineChart'
import SpinnerLoader from '../../components/SpinnerLoader.vue'
import { Connector, Project } from '../../types/main'
import { CONNECTOR_TYPES } from '../../vars/general'
import BaseInputOutlined from '../../components/inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../components/inputs/CustomActivatorSelect.vue'
import _ from 'lodash'
export default Vue.extend({
    name: 'GCB2MarketingReport',
    components: {
        Breadcrumbs,
        SpinnerLoader,
        LineChart,
        CustomActivatorSelect,
        BaseInputOutlined,
    },

    data: () => ({
        pageLoading: false,
        dashboardDataLoading: false,
        dateMenu: false,
        dates: [] as string[],
        staffNames: [] as string[],
        staffNamesList: [] as string[],
        dateFormat: 'DD.MM.YYYY',
        datePickerDateFormat: 'YYYY-MM-DD',
        filters: { productsDisplay: 1 } as any,
        inititalFilters: {},
        dashboardData: {
            visit: {},
            revenue: {},
            averageСheck: {},
            newClients: {},
            uniqClients: {},
            canceledVisits: {},
        } as any,
        chartOptions: {
            legend: {
                labels: {
                    usePointStyle: true,
                },
            },
            plugins: {
                datalabels: false,
            },
            scales: {
                xAxes: [
                    {
                        type: 'category',
                    },
                ],
            },
            tooltips: {
                callbacks: {
                    title: (tooltipItems: any, data: any) => {
                        return data.datasets[tooltipItems[0].datasetIndex].data[tooltipItems[0].index].x
                    },
                },
            },
            maintainAspectRatio: false,
        } as any,
        chartHeight: 300,
    }),
    watch: {},
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        connector(): Connector | undefined {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
        breadcrumbs(): Array<any> {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: 'HR платформа Точек красоты',
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'Общие отчеты',
                    to: '',
                    disabled: true,
                },
            ]
        },
        chartStyles(): any {
            return {
                height: `${this.chartHeight}px`,
                position: 'relative',
            }
        },
        dateRangeText(): string {
            const formatedDates = this.sortedDates.map(date =>
                moment(date, this.datePickerDateFormat).format(this.dateFormat)
            )
            return formatedDates.join(' - ')
        },
        sortedDates(): string[] {
            return [
                ...this.dates.sort(
                    (a, b) =>
                        moment(a, this.datePickerDateFormat).unix() -
                        moment(b, this.datePickerDateFormat).unix()
                ),
            ]
        },
        displayFilters(): any {
            return {
                staffList: Object.values(this.filters.staffList ?? {})
                    .filter((el: any) => el.selected)
                    .map((el: any) => el.option),
            }
        },
        filtersLists(): any {
            return {
                staffList: Object.values(this.filters.staffList ?? {}).map((el: any) => ({
                    id: el.option,
                    name: el.option,
                })),

                productsDisplay: [
                    { id: 1, name: 'Только услуги' },
                    { id: 2, name: 'Услуги и товары, купленные с услугами' },
                    { id: 3, name: 'Все услуги и товары' },
                ],
            }
        },
        queryFilters(): any {
            return _.pickBy(
                {
                    dateFrom: this.sortedDates[0],
                    dateTo: this.sortedDates[1],
                    productsDisplay: this.filters.productsDisplay,
                    staffList: this.filters.staffList,
                },
                (v, k) => {
                    if (_.isObject(v) && _.isEmpty(v)) {
                        return false
                    }
                    if (
                        !_.isNil(v) &&
                        this.displayFilters[k] &&
                        (this.filtersLists[k].length === this.displayFilters[k].length ||
                            this.displayFilters[k].length === 0)
                    ) {
                        return false
                    }
                    return true
                }
            )
        },
    },
    methods: {
        setDefaultDates() {
            this.dates = [
                moment()
                    .subtract(4, 'year')
                    .format(this.datePickerDateFormat),
                moment().format(this.datePickerDateFormat),
            ]
        },

        dropFilters() {
            this.setDefaultDates()
            this.filters = Object.assign({}, this.inititalFilters)
            this.initData()
        },
        setFilterValue(filterId: string, val: any) {
            const updatedFilterVal = {}
            const selectedHmap = val.reduce((a: any, c: any) => Object.assign(a, { [c]: true }), {})
            this.filtersLists[filterId].forEach((el: any) => {
                _.setWith(
                    updatedFilterVal,
                    [el.id],
                    {
                        option: el.id,
                        selected: Boolean(selectedHmap[el.id]),
                    },
                    Object
                )
            })
            this.filters = Object.assign({}, this.filters, { [filterId]: updatedFilterVal })
        },
        async initData() {
            this.dashboardDataLoading = true
            const { data, error } = await getSimpleReportsData({
                projectId: this.project.id,
                filters: this.queryFilters,
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Ошибка')
            }
            this.dashboardData = data.chartsData
            this.filters = Object.assign({}, this.filters, data.filters)
            this.dashboardDataLoading = false
        },
    },
    async mounted() {
        this.inititalFilters = Object.assign({}, this.filters)
        this.pageLoading = true
        this.setDefaultDates()
        await this.initData()
        this.pageLoading = false
    },
})
